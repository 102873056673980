<template>
  <b-sidebar
    id="opened-transaction-add"
    :visible="openedTransactionAdd"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:opened-transaction-add', val)"
  >
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('admin.labels.transaction_add') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <b-card class="card-transaction">
        <!-- BODY -->
        <b-row>
          <b-col cols="12">
            <b-form-group
              :label="$t('admin.labels.user')"
              label-for="user_id"
              :state="errors && errors.user_id ? false : null"
            >
              <v-select
                id="user_id"
                v-model="item.user_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user_id"
                autocomplete="off"
                :searchable="true"
                @search="searchUser"
                @input="getWallets"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="$t('admin.labels.wallet')"
              label-for="wallet_id"
              :state="errors && errors.wallet_id ? false : null"
            >
              <v-select
                id="wallet_id"
                v-model="item.wallet_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="walletOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="wallet_id"
                autocomplete="off"
                :searchable="false"
                @input="setCurrency"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-for="amount"
              :label="$t('admin.labels.amount')"
            >
              <b-row>
                <b-col cols="4">
                  <v-select
                    id="type"
                    v-model="item.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOptions"
                    :clearable="false"
                    class="w-100"
                  />
                </b-col>
                <b-col cols="8">
                  <b-form-input
                    id="amount"
                    v-model="item.amount"
                    type="number"
                    step="0.000001"
                    min="0.000001"
                    max="999999"
                  />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-for="status"
              :label="$t('admin.labels.status')"
            >
              <v-select
                id="status"
                v-model="item.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptionsTransactions"
                :clearable="false"
                class="w-100"
                :reduce="val => val.value"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-for="hidden"
              :label="$t('admin.labels.hidden')"
            >
              <b-form-checkbox
                id="hidden"
                v-model="item.hidden"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-for="description"
              :label="$t('admin.labels.description')"
            >
              <b-form-textarea
                id="description"
                v-model="item.description"
                rows="4"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              block
              @click="submit"
            >
              {{ $t('admin.buttons.save') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>

    </template>
  </b-sidebar>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  mixins: [GlobalMixin],
  model: {
    prop: 'openedTransactionAdd',
    event: 'update:opened-transaction-add',
  },
  props: {
    openedTransactionAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      item: {
        user_id: null,
        wallet_id: null,
        currency_id: null,
        amount: null,
        status: null,
        description: null,
        method: null,
        wallet_number: null,
        hidden: false,
        type: '+',
      },
      userOptions: [],
      walletOptions: [],
      typeOptions: ['+', '-'],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/users')
      .then(response => {
        this.userOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'name')
      })
  },
  methods: {
    hide() {
      this.$store.dispatch('validation/clearErrors')
      this.$emit('update:opened-transaction-add', false)
      this.item = {
        user_id: 0,
        wallet_id: 0,
        amount: 0,
        status: null,
        description: null,
        avatar: null,
      }
    },
    submit() {
      this.$http.post('/api/admin/transactions/', this.item)
        .then(() => {
          this.$store.dispatch('validation/clearErrors')
          this.$emit('refetch-data')
          this.$emit('update:opened-transaction-add', false)

          this.item = {
            user_id: 0,
            wallet_id: 0,
            amount: 0,
            status: null,
            description: null,
            avatar: null,
          }

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.created'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    async searchUser(query) {
      await this.$http.get('/api/users', {
        params: {
          query,
        },
      })
        .then(response => {
          this.userOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'name')
        })
    },
    async getWallets(userId) {
      await this.$http.get('/api/admin/wallets', {
        params: {
          user_id: userId,
        },
      })
        .then(response => {
          this.walletOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'description')
        })
    },
    async setCurrency(walletId) {
      await this.$http.get(`/api/admin/wallets/${walletId}`)
        .then(response => {
          this.item.currency_id = response.data?.data?.currency?.id
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#opened-transaction-add {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
