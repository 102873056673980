<template>
  <b-sidebar
    id="opened-transaction-info"
    :visible="openedTransactionInfo"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:opened-transaction-info', val)"
  >
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('admin.labels.transaction_details') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <b-card class="card-transaction">
        <!-- BODY -->
        <div class="transaction-item">
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                variant="light-primary"
              >
                <feather-icon
                  size="18"
                  icon="CpuIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                Token
                {{ $t('admin.labels.transaction_by') }}
              </h6>
              <small>
                {{ item.token }}
              </small>
            </b-media-body>
          </b-media>
        </div>

        <div class="transaction-item">
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                variant="light-primary"
              >
                <feather-icon
                  size="18"
                  icon="CalendarIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ $t('admin.labels.date') }}
              </h6>
              <small>
                {{ $t('admin.labels.transaction_by') }}
              </small>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder">
            {{ item.created_at }}
          </div>
        </div>

        <div class="transaction-item">
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                variant="light-warning"
              >
                <feather-icon
                  size="18"
                  icon="UserIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ $t('admin.labels.user') }}
              </h6>
              <small>
                {{ $t('admin.labels.transaction_by') }}
              </small>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder text-right">
            <div v-text="item.user.name" />
            <div v-text="item.user.phone" />
            <div v-text="item.user.email" />
          </div>
        </div>

        <div class="transaction-item">
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                variant="light-warning"
              >
                <feather-icon
                  size="18"
                  icon="DollarSignIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ $t('admin.labels.amount') }}
              </h6>
              <small>
                {{ $t('admin.labels.transaction_by') }}
              </small>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder">
            {{ item.type + $options.filters.moneyFormat(item.amount, item.currency) }}
          </div>
        </div>

        <div class="transaction-item">
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
              >
                <feather-icon
                  size="18"
                  icon="AwardIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ $t('admin.labels.status') }}
              </h6>
              <small>
                {{ $t('admin.labels.transaction_by') }}
              </small>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder">
            <b-badge
              v-if="item.status === 'pending'"
              variant="warning"
            >
              {{ $t(`general.statuses.${item.status}`) }}
            </b-badge>
            <b-badge
              v-if="item.status === 'stopped'"
              variant="warning"
            >
              {{ $t(`general.statuses.${item.status}`) }}
            </b-badge>
            <b-badge
              v-if="item.status === 'approved'"
              variant="success"
            >
              {{ $t(`general.statuses.${item.status}`) }}
            </b-badge>
            <b-badge
              v-if="item.status === 'rejected'"
              variant="danger"
            >
              {{ $t(`general.statuses.${item.status}`) }}
            </b-badge>
            <b-badge
              v-if="item.status === 'draft'"
              variant="secondary"
            >
              {{ $t(`general.statuses.${item.status}`) }}
            </b-badge>
          </div>
        </div>

        <div class="transaction-item">
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                variant="light-success"
              >
                <feather-icon
                  size="18"
                  icon="AnchorIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ $t('admin.labels.comment') }}
              </h6>
              <small>
                {{ $t('admin.labels.transaction_by') }}
              </small>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder">
            {{ item.description }}
          </div>
        </div>

        <div
          v-if="item.type === '-'"
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                variant="light-warning"
              >
                <feather-icon
                  size="18"
                  icon="ShoppingBagIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ $t('client.withdraw.labels.method') }}
              </h6>
              <small>
                {{ $t('admin.labels.transaction_by') }}
              </small>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder">
            {{ $t(`client.withdraw.labels.methods.${item.method}`) }}
          </div>
        </div>

        <div
          v-if="item.type === '-'"
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                variant="light-warning"
              >
                <feather-icon
                  size="18"
                  icon="CreditCardIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ $t('client.withdraw.labels.wallet_number') }}
              </h6>
              <small>
                {{ item.wallet_number }} <br>
                {{ item.wallet_bank }}
              </small>
            </b-media-body>
          </b-media>
        </div>
      </b-card>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BCard, BMedia, BMediaBody, BMediaAside, BAvatar, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BSidebar,
    BCard,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
    BBadge,
  },
  model: {
    prop: 'openedTransactionInfo',
    event: 'update:opened-transaction-info',
  },
  props: {
    openedTransactionInfo: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    hide() {
      this.$store.dispatch('validation/clearErrors')
      this.$emit('update:opened-transaction-info', false)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#opened-transaction-info {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
